import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PortfolioItem from "../../components/portfolio-item";

import '../../components/index.scss'
import HomeLink from "../../components/home-link";
import {graphql, useStaticQuery} from "gatsby";

const ConnectSensePage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "portfolio/connect-sense/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero01: file(relativePath: { eq: "portfolio/connect-sense/01.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero02: file(relativePath: { eq: "portfolio/connect-sense/02.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero03: file(relativePath: { eq: "portfolio/connect-sense/03.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = 'ConnectSense';
  const summary = `The crown-jewel application for Grid Connect,
          the ConnectSense app is a HomeKit catalog that was
          supercharged with the introduction of AWS IoT
          for their newer products.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <PortfolioItem
      frameworks="HomeKit, Ruby on Rails, fastlane, Quick, Nimble"
      imageQuery={imageQuery}
      name={title}
      stack="Swift, Ruby"
      url="https://apps.apple.com/us/app/connectsense/id924559441"
      year={2018}
      summary={summary}/>
  </Layout>
};

export default ConnectSensePage
